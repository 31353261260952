@import url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap");

.home-page {
  height: 90vh;
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
    min-height: 35vh;
    max-height: 90%;
  }
  p {
    color: #d4d7d7;
    font-size: 2rem;
    width: 50%;
    margin: 2rem 0;

    @media screen and (max-width: 425px) {
      width: 70%;
    }
  }
  .target {
    height: 20rem;
    width: fit-content;

    @media screen and (max-width: 425px) {
      height: 300px;
    }
  }

  .typewriter-holder {
    color: #00d9ff;
    font-family: "Space Mono", sans-serif;
    font-weight: 800;
    font-size: 53px;
  }

  h1 {
    color: white;
    font-size: 53px;
    margin: 0;
    font-family: Space Mono;
    font-weight: 400;

    .glitch-text {
      font-weight: bold;
      color: rgba(252, 252, 252, 0);
      text-shadow: -0.03em -0.045em 0 #64ffda, -0.1em 0.04em 0 #ff0000;
    }

    &::before {
      content: "<h1>"; //creates <h1> before the body of the text
      font-family: "La Belle Aurore";
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: 15px;
      animation: fadeIn 1s 1.7s backwards; //animation is fadeIn with 1s at the play speed and 1.7s in delay.

      opacity: 0.6;
    }
    &::after {
      content: "</h1>"; //creates <h1> after the body of the text
      font-family: "La Belle Aurore";
      color: #ffd700;
      font-size: 2rem;
      margin-top: 0;
      padding-top: 0;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards; //animation is fadeIn with 1s at the play speed and 1.7s in delay.
      opacity: 0.6;
    }
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  .blink {
    color: #ff0000;
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  a {
    .flat-button {
      pointer-events: stroke;
      background-color: transparent;
      text-decoration-line: none;
      font-family: sans-serif;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      padding: 10px 18px;
      cursor: pointer;
      color: #ffd700;
      border: 1px solid #ffd700;
      margin-top: 25px;
      float: left;
      animation: fadeIn 1s 1.8s backwards;
      white-space: nowrap;

      &:active {
        color: #2b2424;
        background: #ffd700;
      }
    }
  }
}
