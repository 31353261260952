.nav-bar {
  display: block;
  background: transparent;
  width: 60px;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 3;
  min-height: 500px;

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: fit-content;
    top: 50%;
    margin-top: -120px;
    width: 100%;
    padding: 25px 0;
    background-color: rgba($color: #898989, $alpha: 0.3);
    border-radius: 150px;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #00ffc3;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: "";
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &:after {
          content: "HOME";
        }
      }
    }

    a.contact-link {
      &:after {
        content: "CONTACT";
      }
    }

    a.portfolio-link {
      &:after {
        content: "PORTFOLIO";
      }
    }

    a.active {
      svg {
        color: #00ffc3;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 15px;
        line-height: 16px;
        color: #4d4d4e;
      }

      &:hover {
        .anchor-icon {
          color: #ffd700;
        }
      }
    }
  }
}
