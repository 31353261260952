html{
  font-size: 62.5%;

}

body {
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #444;
  /* background: #0a192f; */
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #0a192f);
/*   position: fixed; */
   background-size: 400%;
  margin: 0;
  animation: gradient 15s ease infinite;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}
